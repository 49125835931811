.choose-card{
  margin: 0 auto;
  border-radius: 12px;
  max-width: 400px;
  padding: $s-max;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.04);

  .choose-card-header{
    h1{
      text-align: center;
      font-size: pxRem(28px);
      line-height: pxRem(34px);
      @include wHeavy();
      margin-bottom: $s-micro;
    }

    h2{
      text-align: center;
      @include wBold();
    }
  }

  .m-negate{
    margin-left: -$s-max;
    margin-right: -$s-max;
    margin-bottom: -$s-max;
  }

  .card-section{

    .contact-row{
      cursor: pointer;

      h1{
        font-size: pxRem(18px);
        line-height: pxRem(32px);
        @include wMedium();
        color: $fc-mid;
      }
    }
  }
}