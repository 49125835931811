.card{
  background-color: #FFF;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.04);
  margin-bottom: $s-regular;

  .card-header{
    padding: $s-regular $s-large;
    font-size: pxRem(17px);
    @include wBold();
    vertical-align: middle;

    &.has-btn{
      h2{
        margin: auto 0;
      }
    }

    .btn{
      @include buttonExt(#f9fafb, #2b374d, pxRem(15px));
      background-image: linear-gradient(180deg, #FFF 0%, #f9fafb 100%);
      border: 1px solid #dfe1e4;
    }
  }

  .card-section{
    font-size: pxRem(15px);
    line-height: pxRem(18px);
    padding: $s-regular $s-large;
    border-top: solid 1px $hr-color;
    &:first-child, &.first{
      border: none;
    }

    h2, .inline-header{
      font-size: pxRem(17px);
      @include wBold();
      padding: $s-small 0;

      &:first-child{
        padding-top: 0;
      }
    }
  }
}