.flyout{
  position: relative;

  .flyout-trigger{
    cursor: pointer;
  }



  .flyout-menu, .flyout-mask{
    display: none;
  }

  &.active{
    .flyout-mask{
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      //background: rgba(0,0,0, 0.2);
      z-index: 2;
    }

    .flyout-menu{
      z-index: 3;
      position: absolute;
      top: 100%;
      right: 0;
      display: inline-block !important;
      background-color: #FFF;
      border-radius: 4px;
      box-shadow: 0 4px 16px rgba(21, 26, 37, 0.16), 0 0 0 1px rgba(21, 26, 37, 0.06);
      padding: pxRem(8px) pxRem(24px);

      li{
        display: block !important;
        margin: pxRem(12px) 0 !important;
        white-space: nowrap;
        font-size: pxRem(15px);
        line-height: pxRem(18px);
        @include wMedium();
        cursor: pointer;

        &.active{
          @include wBold();
          cursor: default;
        }
      }
    }
  }
}

