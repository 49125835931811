html{
  font-family: 'Roboto', sans-serif;
  @include wRegular();
  color: #2b374d;
  //letter-spacing: 0.05em;

  body{
    background-color: $white;
  }

  a, .i-link{
    color: #00a0ed;
    text-decoration: none;
    cursor: pointer;

    &:active, &:hover, &:focus{
      text-decoration: underline;
    }

    &.inherit{
      color: inherit !important;
    }
  }
}