.avatar,
.flex.shrink-left > *.avatar:first-child{
  background-color: #2b374d;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  width: pxRem(32px);
  height: pxRem(32px);
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: pxRem(32px);

  > span{
    color: #FFF;
    margin: auto;
    font-size: pxRem(12px);
  }

  &.avatar-small{
    width: pxRem(24px);
    height: pxRem(24px);

    > span{
      font-size: pxRem(9px);
    }
  }

  &.avatar-large{
    width: pxRem(48px);
    height: pxRem(48px);

    > span{
      font-size: pxRem(16px);
    }
  }
}