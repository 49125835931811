.table-card{
  margin: pxRem(40px) 0;

  h2{
    @include wHeavy();
    color: $fc-mid;
    letter-spacing: 0.5px;
    line-height: pxRem(20px);
    margin-bottom: pxRem(16px);
  }

  table{
    width: 100%;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(43, 55, 77, 0.16), 0 0 0 1px rgba(63, 63, 68, 0.05);
    background-color: #FFF;

    tr{
      box-shadow: inset 0 -1px 0 #eaedf0;
      padding: 0 pxRem(8px);
      vertical-align: middle;
    }

    th, td{
      text-align: left;
      padding: pxRem(16px) pxRem(16px) pxRem(15px) pxRem(16px);
      vertical-align: middle;

      &.right-align{
        text-align: right;
      }
    }

    th{
      @include wBold();
    }

    .table-thumbnail{
      display: inline-block;
      width: pxRem(32px);
      height: pxRem(32px);
      background-color: #2b374d;
      background-image: linear-gradient(137deg, #0064bf 0%, #00348b 100%);
      vertical-align: middle;
      margin-right: pxRem(12px);
      overflow: hidden;

      img{
        width: 100%;
      }
    }

    .col-sortable{
      white-space: nowrap;

      .sort-icons{
        display: inline-block;
        padding: 0 pxRem(3px);
        color: $fc-mid;
        font-size: pxRem(12px);
        line-height: inherit;

        .fas{
          display: none;
        }

        &.dir-none{
          .fa-sort{
            display: inline;
          }
        }

        &.dir-asc{
          .fa-sort-up{
            display: inline;
          }
        }

        &.dir-desc{
          .fa-sort-down{
            display: inline;
          }
        }
      }
    }

    .col-primary{
      @include wMedium();
    }
  }
}