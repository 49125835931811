/* You can add global styles to this file, and also import other style files */
@import 'base/base';
@import 'base/reset';
@import 'global/global';
@import 'global/mdb';
@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/solid.scss';
@import '@fortawesome/fontawesome-free/scss/regular.scss';
@import '@fortawesome/fontawesome-free/scss/brands.scss';
@import 'mdb-angular-ui-kit/assets/scss/mdb.scss';
@import 'mdb-angular-file-upload/scss/file-upload.scss';

$my-theme-primary: #032b49; // theme primary color, change this value to customize theme
$my-theme-secondary: #39C0ED; // theme secondary color, change this value to customize theme
$my-theme-surface: #032b49;
$my-theme: mdb-light-theme($my-theme-primary, $my-theme-secondary); // create the new theme using primary and secondary colors

// include theme styles
@include mdb-theme($my-theme);

html, body { height: 100%; overflow-x: hidden!important; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; position: relative; }
.catalog-container {
    // padding: 1rem;
}

.cdk-overlay-pane {
    max-height: 40%!important;
}
.login-snackbar{
    background: $c-blue!important;
    color: white!important;
    font-weight: bold!important;
}

.nav-tabs {
    border-bottom: 1px solid #EFF2F6;

    .nav-link.active {
        color: #263044;
        font-weight: bolder;
    }
}

html a:hover {
    text-decoration: none;
}

html a:focus {
    text-decoration: none;
}


// Style Guide V3
.form-label {
    color: $dgray;
    font-family: $opensans-semi;
    font-weight: $opensans-semi-bold;
}
.form-input {
    background-color: $wgray;
    border-color: $bggray;
    border-radius: $btn-radius;
    font-family: $sans-pro;
    font-weight: $sans-pro-reg;
    color: $dgray;
}
.form-pw-group {
    border-radius: 2px;
    background-color: $wgray;
}
.form-input:focus {
    box-shadow: none!important;
}
.form-input::placeholder {
    color: $cgray;
    font-family: $sans-pro;
    font-weight: $sans-pro-reg;
    font-style: normal;
}

.form-check-input {
    background-color: $wgray;
    border-radius: $btn-radius;
    border: 1px solid $bggray;
    padding: 1px;
}
.form-check-input[type=checkbox]:checked {
    background-color: $blue!important;
    border: 1px solid $blue;
}
.form-check-label {
    font-family: $sans-pro;
    font-weight: $sans-pro-reg;
    font-style: normal;
    color: $dgray;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px $wgray inset;
}
.login {
    margin: 0!important;
    height: 100%;
}


.img-stock {
    margin-top: 10%!important;
}

.nav-tabs .nav-link {
    text-transform: none !important;
    font-family: $opensans-semi;
    font-weight: $opensans-semi-bold;
    color: $c-grey-blue;
    font-size: $fs-btn;
  }


.nav-tabs .nav-link.active {
    text-shadow: 1px 0 black; // make active links BOLDER
    font-weight:bold;
}

.input-group > .form-control {
    border-color: #e0e0e0;
}

.input-group > .form-control:focus {
    border-color: #e0e0e0;
    box-shadow: none;
}

.error-message {
    position: inherit;
}

.input-group-text {
    border: 1px solid #e0e0e0
}
// CSS below is used in the login carousel
.carousel-indicators {
    height: 0!important;
}

.table {
    color: $bgray!important;
}
#map > div.mapboxgl-control-container > div.mapboxgl-ctrl-bottom-left {
    display: none!important;
}
#map > div.mapboxgl-control-container > div.mapboxgl-ctrl-bottom-right {
    display: none!important;
}
.marker-destination {
    background-image: url('assets/images/map-icons-destination.png');
    background-size: cover;
    width: 18px;
    height: 18px;
    border-radius: 50%;
}

.marker {
    background-image: url('assets/images/map-icons-origin.png');
    background-size: cover;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.select-input.form-control {
    height: 2rem!important;
    // font-size: .75rem!important;
    line-height: .75rem!important;
    // font-family: $opensans-semi!important;
    // font-weight: $opensans-semi-bold!important;
    // color: $lbgray!important;
}
mdb-option{
    font-size: .75rem!important;
    color: $lbgray!important;

    .option-text {
      width: 100%!important;
    }
}
.select-input.form-control:focus {
    border: none!important;
    border-color: $white;
    box-shadow: none!important;
}
.select-input.form-control::placeholder {
    color: $lbgray!important;
}

.order-estimate-collaborator > .select-input.form-control {
    height: 2.5rem!important;
    background-color: #FBFBFB !important;
    border-radius: 2px !important;
    border: solid 1px #BBBBBB !important;
}
.order-estimate-collaborator > .select-arrow {
    right: 20px!important;
    top: 12px!important;
}

// mdb-select {
//     .select-input {
//         height: 2.5rem!important;
//     }
//     .select-arrow {
//         color: $lbgray!important;
//         font-size: .6rem!important;
//         right: 11px!important;
//         top: 12px!important;
//     }
// }
// mdb-select:focus {
//     border: none!important;
//     box-shadow: none!important;
// }
.select-wrapper input.active, .select-dropdown:focus{
    border-bottom: 1px solid red !important;
    box-shadow: 0 1px 0 0 red !important;
}

// #cdk-overlay-0 > div > div.select-dropdown{
//     box-shadow: none!important;
// }
// .form-control.select-input {
//     border-radius: 0!important;
// }
// .chart-filter-select > form-notch > .select ~ .form-notch .form-notch-leading {
//     border-radius: 2px 0 0 2px!important;
// }
mdb-form-control > div > div.form-notch-leading {
    // border: 0!important;
}
mdb-form-control > div > div.form-notch-middle {
    // border: 0!important;
}
mdb-form-control > div > div.form-notch-trailing {
    // border: 0!important;
}

// body > app-root > app-base > div.mx-5.my-5.ng-tns-c33-0 > content > app-dashboard > div.row.mt-4.dashboard-main > div.dashboard-metrics.col-7 > div > div:nth-child(1) > dashboard-chart > div > div > div:nth-child(1) > div:nth-child(2) > mdb-form-control > div > div.form-notch-leading {
//     border: 0!important;
// }
// body > app-root > app-base > div.mx-5.my-5.ng-tns-c33-0 > content > app-dashboard > div.row.mt-4.dashboard-main > div.dashboard-metrics.col-7 > div > div:nth-child(1) > dashboard-chart > div > div > div:nth-child(1) > div:nth-child(2) > mdb-form-control > div > div.form-notch-middle {
//     border: 0!important;
// }
// body > app-root > app-base > div.mx-5.my-5.ng-tns-c33-0 > content > app-dashboard > div.row.mt-4.dashboard-main > div.dashboard-metrics.col-7 > div > div:nth-child(1) > dashboard-chart > div > div > div:nth-child(1) > div:nth-child(2) > mdb-form-control > div > div.form-notch-trailing {
//     border: 0!important;
// }


::-webkit-scrollbar {
    display: none;
}
.order-estimate-date {
    background-color: #FBFBFB!important;
    border-color: #BBBBBB!important;
    border-radius: 2px!important;
    font-family: source-sans-pro, sans-serif!important;
    font-weight: 400!important;
    color: #333333!important;
}
.order-estimate-date-toggle .datepicker-toggle-button {
    margin-top: 1rem!important;
    margin-right: 1rem!important;
}

//change tool tip color
.tooltip-inner {
    background-color: $dgray!important;
    max-width: 800px !important;
    color: $white!important;
    border-radius: 2px!important;
    font-size: 0.6875rem!important;
    font-family: $opensans-semi!important;
    font-weight: $opensans-semi-bold!important;
}

.modal-content{
    border-radius: 4px!important;
    border: 1px solid #979797!important;
}

// update to stepper
// Horizontal inactive
mdb-stepper ul.stepper.stepper-horizontal .stepper-step .stepper-head:hover {
    background-color: white!important;
    pointer-events: none!important;
    cursor: default!important;
}
mdb-stepper ul.stepper.stepper-horizontal .stepper-step .stepper-head {
    background-color: white!important;
    pointer-events: none!important;
    cursor: default!important;
}
mdb-stepper ul.stepper.stepper-horizontal .stepper-step .stepper-head .stepper-head-icon {
    background-color: #29374D;
}

// add css for mobile size screen to make the icon 50% font size
@media (max-width: 767px) {
    mdb-stepper ul.stepper.stepper-horizontal .stepper-step {
        font-size: 50%!important;
    }
    mdb-stepper ul.stepper.stepper-horizontal .stepper-step:first-child .stepper-head {
        padding-left: 1rem!important;
    }
    mdb-stepper ul.stepper.stepper-horizontal .stepper-step:last-child .stepper-head {
        padding-right: 1rem!important;

    }
    mdb-stepper ul.stepper.stepper-horizontal .stepper-step .stepper-head .stepper-head-icon {
        font-size: 75%!important;
    }
}
.note {
    border-radius: 2px!important;
}

.collapsible-panel::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
// mdb-stepper > ul > li.stepper-step.ng-tns-c138-1.stepper-active.ng-star-inserted > div > span.stepper-head-icon.ng-tns-c138-1
.pd-options.accordion-flush > .accordion-item:last-child {
    border-bottom: 1!important;
}
.modal {
    overflow-y: hidden!important;
}
.file-upload-mask {
    background-color: #FFFFFF!important;
}
.preview-img { 
    height: 400px;
    width: auto;
    display: flex;

    @media (width < 900px) {
        width: 100%;
        height: auto;
    }

    svg {
        display: block!important;
        width: auto!important;
        height: 400px!important;
        margin: 0 auto!important;

        @media (width < 900px) {
            width: 100% !important;
            height: auto !important;
        }
    }
}
textarea {
    resize: none!important;
}

.color-picker  {
    border-radius: 13px !important;
    border: 1px solid var(--background-decorative-low-emphasis, #F7F7F7) !important;
    background: var(--background-default, #FFF) !important;
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.10) !important;

    >*:not(.preset-area) {
      display: none!important;
    }
}

.saturation-lightness {
    border-top-left-radius: 13px !important;
    border-top-right-radius: 13px !important;
}

.preset-color {
    border-radius: 50% !important
}
mdb-accordion .expanded-item .accordion-button:not(.collapsed) {
    background-color: #F7F7F7 !important;
    color: black!important;
    box-shadow: none!important;
    border-radius: .50rem!important;
    padding-bottom: 0!important;

}
mdb-accordion .expanded-item .accordion-body {
    padding-top: 0!important;
}
.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234f4f4f'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/%3e%3c/svg%3e")
}

.accordion-button:not(.collapsed) {
    color: #414042!important;

}

.accordion-button {
    background-color: #EFEFEF !important;
}

.cursor-pointer {
    cursor: pointer;
}
.pd-options .accordion-button {
    padding-left: 0!important;
}
.form-control:focus {
    box-shadow: none!important;
}
.canvas-container {
  z-index: 1;
  transition: all .5s;
}
canvas#fabricCanvas {
  position: absolute!important;
  transition: all .5s;
}
.file-upload-default-message {
    text-align: justify!important;
    font-style: italic!important;
    width: 75%!important;
    margin-top: 1rem!important;
}
.upload-art-files-modal {
    max-height: 800px;
    height: 800px;
}
.skeleton-loader {
    background-color: #f0f0f0; /* skeleton loader background color */
    background-image: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%); /* linear background animation */
    background-size: 200% 100%; /* linear background size  */
    animation: skeleton-loading 1.5s infinite linear; /* infinite loading animation */
}
  
@keyframes skeleton-loading {
    0% {
      background-position: 100% 50%; /* background initial position */
    }
    100% {
      background-position: -100% 50%; /* background final position */
    }
}
.transparent-background-pattern {
    background-image: linear-gradient(45deg, #e0e0e0 25%, transparent 25%), linear-gradient(-45deg, #e0e0e0 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #e0e0e0 75%), linear-gradient(-45deg, transparent 75%, #e0e0e0 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
    backdrop-filter: contrast(0.9);
}
.transition-all {
    transition: all 0.5s;
}
.icon-button {
    width: 40px;
    height: 40px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 1s;
    &:active {
        background: rgba(0, 0, 0, 0.10);
    }
}
.w-sm-30 {
    @media (width < 550px) {
        width: 30%;
    }
}
.border-white {
    border-color: white;
}
.right-1 {
    right: 5px;
}
.top-1 {
    top: 5px;
}
.z-index-2 {
    z-index: 2;
}