.data-set{
  .data-item{
    padding: $s-micro 0;

    .data-label{
      color: $fc-mid;
    }

    &.data-two-thirds{
      .data-label{
        width: 33.3333333%
      }
      .data-value{
        width: 66.666666%;
      }
    }
  }
}