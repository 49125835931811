@use "sass:math";

@function pxRem($size) {
  $remSize: math.div($size , 16px);
  @return #{$remSize}rem;
}

@mixin clearSelf(){
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin boxShadow(){
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
}

@mixin wRegular(){
  font-weight: 300;
}
.w-regular{
  @include wRegular();
}

@mixin wMedium(){
  font-weight: 400;
}
.w-medium{
  @include wMedium();
}

@mixin wBold(){
  font-weight: 500;
}
.w-bold{
  @include wBold();
}

@mixin wHeavy(){
  font-weight: 700;
}
.w-heavy{
  @include wHeavy();
}


@mixin buttonSlimMod(){
  &.btn-slim{
    font-size: pxRem(14px);
    line-height: pxRem(24px);
    @include wBold();
    white-space: nowrap;
  }
}

@mixin button($color, $font-size: inherit){
  display: inline-block;
  background-color: $color;
  color: #FFF;
  text-decoration: none;
  border: none;
  border-radius: 3px;
  font-size: $font-size;
  line-height: 2.5em;
  padding: 0 1em;

  @include buttonSlimMod();
}

@mixin buttonExt($bg-color, $text-color, $font-size: inherit){
  display: inline-block;
  background-color: $bg-color;
  color: $text-color;
  text-decoration: none;
  border: none;
  border-radius: 3px;
  font-size: $font-size;
  line-height: 2.5em;
  padding: 0 1em;

  @include buttonSlimMod();
}

@mixin textinput($font-size: inherit){
  border-radius: 4px;
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.06);
  border: 1px solid #dfe1e4;
  line-height: 2.5em;
  padding: 0 1em;
}

@mixin fas($unicode){
  content: $unicode;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
}

