.paginated-table{

}

.pagination-ui{
  margin: pxRem(16px) 0;

  .pagination-pages{
    list-style: none;
    display: flex;
    justify-content: center;

    .pagination-page{
      border: 1px solid $fc-light;
      color: $fc-regular;
      background-color: #FFF;
      border-radius: pxRem(4px);
      padding: pxRem(4px) pxRem(8px);
      margin: 0 pxRem(4px);
      cursor: pointer;

      &.active{
        color: $fc-light;
        background-color: $bg-color;
        cursor: auto;
      }
    }

    .pagination-ellip{
      padding: pxRem(2px) pxRem(4px);
    }
  }
}