button[type=button]:disabled{
  opacity: 0.65;
}

.btn-orange{
  @include button($c-orange);
}

.btn-blue{
  @include button($c-blue);
}

.btn-gray{
  @include buttonExt(#dbe0e8, $fc-mid);
}

.btn-light-gray{
  @include buttonExt(#e9ecf1, $fc-mid);
}

.btn-download{
  @include button($c-blue);
  font-size: pxRem(12px);
}