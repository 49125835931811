.box-shadow{
  @include boxShadow();
}

.hide{
  display: none !important;
}

.t-center, .center-align{
  text-align: center;
}

.t-left, .left-align{
  text-align: left;
}

.t-right, .right-align{
  text-align: right;
}

.clear-self{
  @include clearSelf();
}

.i-pointer{
  cursor: pointer;

  &:focus{
    outline: none;
  }
}

.f-right{
  float: right;
}

.f-left{
  float:left;
}

.full-w{
  width: 100%;
}

.fa-ellipsis-h{
  color: $fc-mid;
}

.p-top-none{
  padding-top: 0 !important;
}
.p-bottom-none{
  padding-bottom: 0 !important;
}
.p-right-none{
  padding-right: 0 !important;
}
.p-left-none{
  padding-left: 0 !important;
}

.m-top-none{
  margin-top: 0 !important;
}
.m-bottom-none{
  margin-bottom: 0 !important;
}
.m-right-none{
  margin-right: 0 !important;
}
.m-left-none{
  margin-left: 0 !important;
}

.m-top-1{
  margin-top: 1rem;
}
.m-right-1{
  margin-right: 1rem;
}
.m-bottom-1{
  margin-bottom: 1rem;
}
.m-left-1{
  margin-left: 1rem;
}

hr{
  display:block;
  height: 1px;
  border: none;
  background: $hr-color;
}

.flex{
  display: flex;
  justify-content: space-between;

  &.top-align{
    align-items: flex-start;
  }

  &.shrink-left{
    >*:first-child{
      flex: 0 1 auto;
    }

    >*:last-child{
      margin: auto auto auto pxRem(16px);
      flex: 1 0 auto;
    }
  }

  > .col-one-third{
    width: 33.333333333%;
  }

  > .col-two-third{
    width: 66.666666666%;
  }
}

.ph{
  color: #F00 !important;
}

.ellip{
  max-width: 100%;
  text-overflow: ellipsis !important;
  overflow: hidden;
}