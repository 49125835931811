.page-section{
  &.max-width{
    max-width: $w-page-max;
    margin: 0 auto;
  }

  &.layout-sidebar{

    .col-left{
      width: calc(100% - 19.5rem);
    }

    .col-right{
      width: $w-sidebar;

      hr{
        width: $w-sidebar;
        margin: 0.75rem 0 0.75rem -2rem;
      }
    }
  }

  .page-header{
    margin-bottom: $module-padding;

    .page-crumbtrail{
      color: $fc-mid;
      margin-bottom: $s-small;

      li{
        display: inline-block;

        &::before{
          @include fas('\f053');
          margin: 0 pxRem(8px);
        }

        &:first-child{
          &::before{
            margin-left: 0;
          }
        }
      }
    }

    .page-title{
      @include wHeavy();
      font-size: pxRem(28px);
      line-height: pxRem(32px);
      margin-bottom: $s-max;
    }

    .page-nav{
      float:right;
      ul{
        li{
          display: inline-block;
          margin: 0 pxRem(6px);

          > *{
            height: pxRem(40px);
          }

          input{
            @include textinput();
          }

          button{
            @include button($btn-color);
          }
        }
      }
    }
  }
}