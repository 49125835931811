@import 'base/base';

.mdb-input-group {
    background: $c-white;
    box-shadow: 0 0 6px $c-gray;
    border-radius: 5px;
}

.mdb-input-control:focus {
    box-shadow: unset !important;
  }

@media (min-width: 768px) {
  .col-md-2 {
    // height: calc(100vh - 300px);
      overflow-y: scroll;
  }
}