// Style Guide - Platform V3

// Colors
$lblue: #3FA9F5;
$blue: #1D44FA;
$pink: #FF2E6C;
$lpink: #FBE9EA;
$green: #28C76F;
$lgreen: #F2FFF2;
$bgray: #2B374D;
$bdgray: #2D374B;
$lbgray: #717E94;
$dgray: #333333; // used mainly in fonts
$bgray: #263044; // used dashboard color title 
$cgray: #999999;
$bggray: #BBBBBB;
$ogray: #F6F6F6;
$stgray: #767676;
$imgray: #E0E4E4; //used in image carousel background for cs
$wgray: #FBFBFB;
$lwgray: #F5F7FA;
$red: #E40404;
$yellow: #FFD946;
$black: #000000;
$white: #FFFFFF;

$grid-breakpoints: ( xs: 0, sm: 390px, md: 768px, lg: 992px, xl: 1200px, xxl:
1400px );

// Fonts
$opensans-semi: 'Open Sans', sans-serif;
$opensans-semi-bold: 600;
$opensans-semi-reg: 400;

$sans-pro: source-sans-pro,sans-serif;
$sans-pro-bold: 600;
$sans-pro-reg: 400;
$sans-pro-style: normal;

$btn-radius: 2px;

// End Style Guide - Platform V3

$page-padding: pxRem(40px);
$module-padding: pxRem(20px);

$sidebar-width: pxRem(64px);
$header-height: pxRem(56px);

// Font Color
$fc-regular: #2b374d;
$fc-mid: #717e94;
$fc-light: #a2abbb;
$fc-x-light: #d2dbeb;
$fc-darkBlue: #344056;

// Font Sizing
$fs-btn: 0.9375rem;

// Width
$w-page-max: pxRem(960px);
$w-sidebar: pxRem(288px);

// Spacing
$s-micro: pxRem(8px);
$s-small: pxRem(16px);
$s-regular: pxRem(24px);
$s-large: pxRem(32px);
$s-max: pxRem(40px);

$c-orange: #E37B3A;
$c-purple: #8000EA;
$c-green: #19CEA5;
$c-blue-dark: #227BE5;
$c-blue: #009bea;
$c-red: #D7336B;
$c-gray: #DBE0E8;
$c-gold: #BA8D2E;
$c-light-golf: #ebd8af;
$c-light-blue: #d0efff;
$c-dark-blue: #002537;
$bg-color: #F9FAFC;
$btn-color: #009bea;
$hr-color: #eaedf0;
$m-blue-primary: #032B49;
$c-white: #ffff;
$c-inactive-route: #A5B5C9;
$c-best: #FF9300;
$c-hover-grey: #e8e8e8;
$c-grey-blue: #4C586E;