#component_search{
  position: relative;

  #search_input{
    padding-left: pxRem(38px);
  }

  #search_submit{
    background: none;
    padding: 0 pxRem(12px);
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    color: #c1c9d6;
  }

}